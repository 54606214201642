import React, {Component} from 'react';


class SingleTwoColumnText extends Component{
    render(){
        var {Title, LeftText, RightText} = this.props;
        return(
            <div className="two_column_text">
                <div className="b_single_title">
                    <h3>{Title}</h3>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <p>{LeftText}</p>
                    </div>
                    <div className="col-lg-6">
                        <p>{RightText}</p>
                    </div>
                </div>
            </div>
        )
    }
}
export default SingleTwoColumnText;