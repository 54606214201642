import React, {Component} from 'react';

class FTAboutWD extends Component{
    render(){
        let FooterData = this.props.FooterData; 
        var {Image} = this.props;
        return(
            <div className="col-lg-3 col-md-6">
                <aside className="f_widget about_widget">
                    <img src={require("../../assets/img/" + Image)} alt="" />
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusa nt ium dolor emque laudantium totam.</p>
                    <ul className="list f_social">
                    {
                        FooterData.AboutSocial.map(item =>{
                            return(
                                
                                <li key={item.id}><a href={item.url}><i className={item.icon}></i></a></li>
                            )
                        })
                    }
                    </ul>
                </aside>
            </div>
        );
    }
}
export default FTAboutWD;