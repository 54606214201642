import React from 'react';

import HeaderTopBar from '../HeaderTopBar';
import Breadcrumb from '../Elements/Breadcrumb';
import Footer from '../Elements/Footer'; 
import ContactForm from '../Elements/ContactForm';


export const Contact = () => (
    <div className="body_wrapper">
        <HeaderTopBar Logo="logo.png" />
        <Breadcrumb PageName="Contact Us" PageLink="Contact" />
        <ContactForm />
        <Footer CPLeft="© Copyright MarketWatch" CPRight="All right reserved." /> 
    </div>
)