import React, {Component} from 'react';

class LoanSearchList extends Component{
    render(){
        var {Url, Icon, Text} = this.props;
        return(
            <li><a href={Url}><i className={Icon}></i>{Text}</a></li>
        )
    }
}
export default LoanSearchList;