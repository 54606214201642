import React, {Component} from 'react';
import BlogTwoColumnItem from './BlogTwoColumnItem';

class BlogTwoColumnList extends Component{
    render(){
        return(
            <section class="main_blog_area p_100">
                <div class="container">
                    <div class="main_blog_inner">
                        <div class="blog_item_inner row">
                            <BlogTwoColumnItem
                                Image="blog-1.jpg" 
                                Title="Accusantium Dolore Mque Laud Antium Dolore Mque"
                                Details="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
                                BtnText="Read More"
                                Url="#"
                                Author="Admin"
                                Comment="comments: 04"
                            />
                            <BlogTwoColumnItem
                                Image="blog-2.jpg" 
                                Title="It is a long established fact that a reader"
                                Details="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
                                BtnText="Read More"
                                Url="#"
                                Author="Admin"
                                Comment="comments: 04"
                            />
                            <BlogTwoColumnItem
                                Image="blog-3.jpg" 
                                Title="The point of using Lorem Ipsum is that it has."
                                Details="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
                                BtnText="Read More"
                                Url="#"
                                Author="Admin"
                                Comment="comments: 04"
                            />
                            <BlogTwoColumnItem
                                Image="blog-4.jpg" 
                                Title="Content here, content here it look like readable English."
                                Details="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
                                BtnText="Read More"
                                Url="#"
                                Author="Admin"
                                Comment="comments: 04"
                            />
                            <BlogTwoColumnItem
                                Image="blog-5.jpg" 
                                Title="It is a long established fact that a reader will"
                                Details="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
                                BtnText="Read More"
                                Url="#"
                                Author="Admin"
                                Comment="comments: 04"
                            />
                            <BlogTwoColumnItem
                                Image="blog-6.jpg" 
                                Title="Accusantium Dolore Mque Laud Antium Dolore Mque"
                                Details="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
                                BtnText="Read More"
                                Url="#"
                                Author="Admin"
                                Comment="comments: 04"
                            />
                        </div>
                        <nav aria-label="Page navigation example">
                            <ul class="pagination">
                                <li class="page-item">
                                    <a class="page-link" href=".#" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                    <span class="sr-only">Previous</span>
                                    </a>
                                </li>
                                <li class="page-item"><a class="page-link" href=".#">1</a></li>
                                <li class="page-item"><a class="page-link" href=".#">2</a></li>
                                <li class="page-item"><a class="page-link" href=".#">3</a></li>
                                <li class="page-item">
                                    <a class="page-link" href=".#" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                    <span class="sr-only">Next</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>
        )
    }
}
export default BlogTwoColumnList;