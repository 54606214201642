import React, {Component} from 'react';

import GrowthReport from './GrowthReport';
import InvestmentItem from './InvestmentItem';

class InvestmentDetails extends Component{
    render(){
        return(
            <section className="service_details_inner p_100">
                <div className="container">
                    <div className="row service_details_inner investment flex-row-reverse">
                        <div className="col-lg-9">
                            <div className="mutual_funds_text">
                                <InvestmentItem
                                    Image="m-service-4.jpg"
                                    Title="INVESTMENT IN BONDS"
                                    Details1="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors."
                                    Details2="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable."
                                    Details3="It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc."
                                />
                            </div>
                            <GrowthReport Title="DEVELOPMENT STRATEGY" Details="With small business loans up to Rs.30 lakh, funding for your small business is now just 24 hours away. Use the funds to invest in infrastructure, expand operations, upgrade to the latest plant and machinery, maintain inventory, or to even increase working capital. These customised loans can give your business the much-needed boost to help your enterprise scale to new heights with enhanced competitiveness and profitability." />
                        </div>
                        <div className="col-lg-3">
                            <div className="left_sidebar_area">
                                <aside className="left_widget service_list_widget">
                                    <ul className="list">
                                        <li className="active"><a href="/Pages/BusinessLoan">Business Loan <i className="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                        <li><a href="/Pages/Planning">Financial Planning <i className="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                        <li><a href="/Pages/BusinessGrowth">Business Growth <i className="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                        <li><a href="/Pages/Investment">Invesment In Bonds <i className="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                        <li><a href="/Pages/MutualFunds">Mutual Funds <i className="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                    </ul>
                                </aside>
                                <aside className="left_widget contact_s_widget">
                                    <h4>Contact Us</h4>
                                    <p>We here to help in anything you need. Call us on below number.</p>
                                    <a href="tel:+8801676790690"><i className="lnr lnr-phone-handset"></i>+88016 76790 690</a>
                                    <a href="mailto:rockybd1995@gmail.com"><i className="lnr lnr-envelope"></i>rockybd1995@gmail.com</a>
                                </aside>
                                <aside className="left_widget download_widget">
                                    <a href=".#"><i className="lnr lnr-download"></i> Download Your PDF</a>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default InvestmentDetails;