import React, {Component} from 'react';
import WDTitle from './WDTitle';

class TextWD extends Component{
    render(){
        return(
            <aside className="r_widget text_widget">
                <WDTitle Title="Text Widget" />
                <p>{this.props.Text}</p>
            </aside>
        )
    }
}
export default TextWD;