import React, {Component} from 'react';

class SearchWD extends Component{
    render(){
        return(
            <aside className="r_widget search_widget">
                <div className="input-group">
                    <input type="text" className="form-control" placeholder="Search" />
                    <div className="input-group-append">
                        <button className="btn" type="button" id="button-addon2"><i className="icon icon-Search"></i></button>
                    </div>
                </div>
            </aside>
        )
    }
}
export default SearchWD;