import React, {Component} from 'react';

class Facility extends Component{
    render(){
        var {Text, Icon} = this.props;
        return(
            <div className="col-lg-3 col-md-6">
                <div className="funds_faci_item">
                    <i className={Icon}></i>
                    <h5>{Text}</h5>
                </div>
            </div>
        )
    }
}
export default Facility;