import React from 'react';

import HeaderTopBar from '../HeaderTopBar';
import Breadcrumb from '../Elements/Breadcrumb';
import WorkProcess from '../Elements/WorkProcess';
import Footer from '../Elements/Footer'; 
import FasterService from '../Elements/FasterService';
import RequestQuote from '../Elements/RequestQuote';
import ClientSlider from '../Elements/ClientSlider';


export const Service = () => (
    <div className="body_wrapper">
        <HeaderTopBar Logo="logo.png" />
        <Breadcrumb PageName="Services" PageLink="Service" />
        <WorkProcess />
        <FasterService />
        <RequestQuote />
        <ClientSlider />
        <Footer CPLeft="© Copyright MarketWatch" CPRight="All right reserved." />
    </div>
)