import React, {Component} from 'react';

class RequestForm extends Component{
    render(){
        return(
            <form className="row request_form" action="contact_process.php" method="post" id="contactForm" novalidate="novalidate">
                <div className="form-group col-md-12">
                    <input type="text" className="form-control" id="name" name="name" placeholder="Your Name" />
                </div>
                <div className="form-group col-md-6">
                    <input type="email" className="form-control" id="email" name="email" placeholder="Email Address" />
                </div>
                <div className="form-group col-md-6">
                    <input type="text" className="form-control" id="subject" name="subject" placeholder="Subject" />
                </div>
                <div className="form-group col-md-12">
                    <textarea className="form-control" name="message" id="message" rows="1" placeholder="Wrtie Message"></textarea>
                </div>
                <div className="form-group col-md-12">
                    <button className="btn submit_btn" type="button" id="button-addon2">{this.props.BtnText} <strong className="icon"><i className="fa fa-paper-plane" aria-hidden="true"></i></strong></button>
                </div>
            </form>
        )
    }
}
export default RequestForm;