import React, {Component} from 'react';
import FillterMenu from './FillterMenu';
import GalleryItem from './GalleryItem';


class GalleryIsoTop extends Component{
    render(){
        return(
            <section className="gallery_classic_area p_100">
                <div className="container">
                    <div className="g_fillter">
                        <ul className="list">
                            <FillterMenu Class="active" ID="*" Name="All" />
                            <FillterMenu Class="" ID=".cash" Name="Cash Flow" />
                            <FillterMenu Class="" ID=".project" Name="FINANCIAL PROJECT" />
                            <FillterMenu Class="" ID=".investment" Name="INVESTMENTS" />
                            <FillterMenu Class="" ID=".invoicing" Name="INVOICING" />
                        </ul>
                    </div>
                    <div className="row g_classic_inner">
                        <GalleryItem  
                            ID="col-sm-6 cash invoicing"
                            Image="g-1.jpg"
                            Details="It esteems luckily mr or picture placing drawing apartments frequently"
                            BtnText="Details"
                            Url="#"
                        />
                        <GalleryItem  
                            ID="col-sm-6 project investment"
                            Image="g-2.jpg"
                            Details="It esteems luckily mr or picture placing drawing apartments frequently"
                            BtnText="Details"
                            Url="#"
                        />
                        <GalleryItem  
                            ID="col-sm-6 cash invoicing"
                            Image="g-3.jpg"
                            Details="It esteems luckily mr or picture placing drawing apartments frequently"
                            BtnText="Details"
                            Url="#"
                        />
                        <GalleryItem  
                            ID="col-sm-6 project invoicing"
                            Image="g-4.jpg"
                            Details="It esteems luckily mr or picture placing drawing apartments frequently"
                            BtnText="Details"
                            Url="#"
                        />
                        <GalleryItem  
                            ID="col-sm-6 cash investment"
                            Image="g-5.jpg"
                            Details="It esteems luckily mr or picture placing drawing apartments frequently"
                            BtnText="Details"
                            Url="#"
                        />
                        <GalleryItem  
                            ID="col-sm-6 cash invoicing"
                            Image="g-7.jpg"
                            Details="It esteems luckily mr or picture placing drawing apartments frequently"
                            BtnText="Details"
                            Url="#"
                        />
                        <GalleryItem  
                            ID="col-sm-6 project investment"
                            Image="g-8.jpg"
                            Details="It esteems luckily mr or picture placing drawing apartments frequently"
                            BtnText="Details"
                            Url="#"
                        />
                    </div>
                </div>
            </section>
        )
    }
}
export default GalleryIsoTop;