import React, {Component} from 'react';
import LoanSearchList from './LoanSearchList';

class LoanText extends Component{
    render(){
        var {Image, Title, Details1, Details2} = this.props;
        return(
            <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="single_title">
                        <h2>{Title}</h2>
                    </div>
                    <p>{Details1}</p>
                    <p>{Details2}</p>
                    <ul className="list">
                        <LoanSearchList Url="#" Icon="lnr lnr-arrow-right" Text="Market Research" />
                        <LoanSearchList Url="#" Icon="lnr lnr-arrow-right" Text="Investment Planning" />
                        <LoanSearchList Url="#" Icon="lnr lnr-arrow-right" Text="Business Growth" />
                        <LoanSearchList Url="#" Icon="lnr lnr-arrow-right" Text="Company Growth" />
                    </ul>
                </div>
                <div className="col-lg-5">
                    <img className="img-fluid" src={require("../../assets/img/service/" + Image)} alt="" />
                </div>
            </div>
        )
    }
}
export default LoanText;