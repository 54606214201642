import React, {Component} from 'react';
import FTWDTitle from './FTWDTitle'

class FTSubscribeWD extends Component{
    render(){
        return(
            <div className="col-lg-4 col-md-6">
                <aside className="f_widget news_widget">
                    <FTWDTitle TitleText="Newsletter" />
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder="Enter Your Email" aria-label="Recipient's username" aria-describedby="button-addon2" />
                        <div className="input-group-append">
                            <button className="btn submit_btn" type="button" id="button-addon3">Subscribe <strong className="icon"><i className="fa fa-paper-plane" aria-hidden="true"></i></strong></button>
                        </div>
                    </div>
                </aside>
            </div>
        )
    }
}
export default FTSubscribeWD;