import React from 'react';
// import { Link } from 'react-router-dom';
import Error from '../Elements/Error';



export const NotFound = () => (
    <div className="body_wrapper">
        <Error />
    </div>
)