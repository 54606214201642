import React, {Component} from 'react';


class InvestmentItem extends Component{
    render(){
        var {Image, Title, Details1, Details2, Details3} = this.props;
        return(
            <div className="investment_text">
                <img className="img-fluid" src={require("../../assets/img/service/" + Image)} alt="" />
                <div className="single_title">
                    <h2>{Title}</h2>
                </div>
                <p>{Details1}</p>
                <p>{Details2}</p>
                <p>{Details3}</p>
            </div>
        )
    }
}
export default InvestmentItem;