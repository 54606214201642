const FooterData = {
    AboutSocial: [
        {
            id: 1,
            url: 'https://www.facebook.com/',
            icon: 'fa fa-facebook',
        },
        {
            id: 2,
            url: 'https://twitter.com/',
            icon: 'fa fa-twitter',
        },
        {
            id: 3,
            url: 'https://www.instagram.com/',
            icon: 'fa fa-instagram',
        },
        {
            id: 4,
            url: 'https://www.pinterest.com/',
            icon: 'fa fa-pinterest',
        },
    ],
    PagesLink: [
        {
            id: 1,
            Text: 'About Company',
            url: '#',
        },
        {
            id: 2,
            Text: 'Career',
            url: '#',
        },
        {
            id: 3,
            Text: 'Team',
            url: '#',
        },
        {
            id: 4,
            Text: 'Testimonials',
            url: '#',
        },
        {
            id: 5,
            Text: 'Blog',
            url: '#',
        }
    ]
};
export default FooterData;
