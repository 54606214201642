import React, {Component} from 'react';
import WDTitle from './WDTitle';
import RecentPostList from './RecentPostList';

class RecentPostWD extends Component{
    render(){
        return(
            <aside className="r_widget recent_widget">
                <WDTitle Title="Recent Posts" />
                <div className="recent_inner">
                    <RecentPostList 
                        Image="recent-1.jpg"
                        Title="Accusantium Dolore Mque Laud Antium Dolore Mque"
                        Date="19 Mar 2019"
                        Url="#"
                    />
                    <RecentPostList 
                        Image="recent-2.jpg"
                        Title="Accusantium Dolore Mque Laud Antium Dolore Mque"
                        Date="19 Mar 2019"
                        Url="#"
                    />
                    <RecentPostList 
                        Image="recent-3.jpg"
                        Title="Accusantium Dolore Mque Laud Antium Dolore Mque"
                        Date="19 Mar 2019"
                        Url="#"
                    />
                    <RecentPostList 
                        Image="recent-4.jpg"
                        Title="Accusantium Dolore Mque Laud Antium Dolore Mque"
                        Date="19 Mar 2019"
                        Url="#"
                    />
                    
                </div>
            </aside>
        )
    }
}
export default RecentPostWD;