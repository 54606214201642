import React, {Component} from 'react';
import FTWDTitle from './FTWDTitle';

class FTContactWD extends Component{
    render(){
        var {Address, Number, Email} = this.props;
        return(
            <div className="col-lg-3 col-md-6">
                <aside className="f_widget contact_widget">
                    <FTWDTitle TitleText="CONTACT INFO" />
                    <p>{Address}</p>
                    <a href=".#"><span>Call Us: </span>{Number}</a>
                    <a href=".#"><span>Email : </span> {Email}</a>
                </aside>
            </div>
        )
    }
}
export default FTContactWD;