import React, {Component} from 'react';

class RecentPostList extends Component{
    render(){
        var {Image, Title, Date, Url} = this.props;
        return(
            <div className="media">
                <div className="d-flex">
                    <img className="img-fluid" src={require("../../../assets/img/blog/recent-post/" + Image)} alt="" />
                </div>
                <div className="media-body">
                    <a href={Url}><h4>{Title}</h4></a>
                    <a className="date" href={Url}>{Date}</a>
                </div>
            </div>
        )
    }
}
export default RecentPostList;