import React from 'react';


import HeaderTopBar from '../HeaderTopBar';
import Breadcrumb from '../Elements/Breadcrumb';
import Footer from '../Elements/Footer'; 
import GalleryFull from '../Elements/GalleryFull';

export const GalleryFullwidth = () => (
    <div className="body_wrapper">
        <HeaderTopBar Logo="logo.png" />
        <Breadcrumb PageName="GALLERY FULL WIDTH" PageLink="GalleryFullwidth" />
        <GalleryFull />
        <Footer CPLeft="© Copyright MarketWatch" CPRight="All right reserved." /> 
    </div>
)