import React, {Component} from 'react';

import SearchWD from './widgets/SearchWD';
import CategoriesWD from './widgets/CategoriesWD';
import RecentPostWD from './widgets/RecentPostWD';
import TagWD from './widgets/TagWD';
import TextWD from './widgets/TextWD';
import SingleBlogItem from './SingleBlogItem';
import SingleTwoColumnText from './SingleTwoColumnText';

class BlogDetails extends Component{
    render(){
        return(
            <section className="main_blog_area single_blog_area p_100">
                <div className="container">
                    <div className="row main_blog_inner">
                        <div className="col-lg-9">
                            <div className="blog_item_inner">
                                <SingleBlogItem 
                                    Image="blog-1.jpg"
                                    Title="Accusantium Dolore Mque Laud Antium Dolore Mque"
                                    Details1="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
                                    Details2="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium dolore que laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi arch itecto beatae vitae dict eaque ipsa quae."
                                    Details3="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
                                    Author="Admin"
                                    Comment="Comments:04"
                                />
                                <SingleTwoColumnText 
                                    Title="TWO COLUMN TEXT SAMPLE"
                                    LeftText="Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium dolore que laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis."
                                    RightText="Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium dolore que laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis."
                                />
                                <div className="s_comment_list">
                                    <div className="b_single_title">
                                        <h3>Comments 4</h3>
                                    </div>
                                    <div className="s_comment_list_inner">
                                        <div className="media">
                                            <div className="d-flex">
                                                <img src={require("../../assets/img/comment/comment-1.jpg")} alt="" />
                                            </div>
                                            <div className="media-body">
                                                <a href=".#"><h4>Michale Joe</h4></a>
                                                <p>Duis aute irure dolor in reprehenderit in vol uptate velit esse cillum dolore eu fugiat nulla pari atur. Excepteur sint occaecat cupidatat non proid pent.</p>
                                                <div className="date_rep">
                                                    <a href=".#">Nov 27 2017</a>
                                                    <a href=".#">Reply</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="media">
                                            <div className="d-flex">
                                                <img src={require("../../assets/img/comment/comment-1.jpg")} alt="" />
                                            </div>
                                            <div className="media-body">
                                                <a href=".#"><h4>Michale Joe</h4></a>
                                                <p>Duis aute irure dolor in reprehenderit in vol uptate velit esse cillum dolore eu fugiat nulla pari atur. Excepteur sint occaecat cupidatat non proid pent.</p>
                                                <div className="date_rep">
                                                    <a href=".#">Nov 27 2017</a>
                                                    <a href=".#">Reply</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="media">
                                            <div className="d-flex">
                                                <img src={require("../../assets/img/comment/comment-1.jpg")} alt="" />
                                            </div>
                                            <div className="media-body">
                                                <a href=".#"><h4>Michale Joe</h4></a>
                                                <p>Duis aute irure dolor in reprehenderit in vol uptate velit esse cillum dolore eu fugiat nulla pari atur. Excepteur sint occaecat cupidatat non proid pent.</p>
                                                <div className="date_rep">
                                                    <a href=".#">Nov 27 2017</a>
                                                    <a href=".#">Reply</a>
                                                </div>
                                                <div className="media">
                                                    <div className="d-flex">
                                                        <img src={require("../../assets/img/comment/comment-1.jpg")} alt="" />
                                                    </div>
                                                    <div className="media-body">
                                                        <a href=".#"><h4>Michale Joe</h4></a>
                                                        <p>Duis aute irure dolor in reprehenderit in vol uptate velit esse cillum eu fugiat nulla pari atur. Excepteur sint occaecat cupidatat non.</p>
                                                        <div className="date_rep">
                                                            <a href=".#">Nov 27 2017</a>
                                                            <a href=".#">Reply</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="media">
                                            <div className="d-flex">
                                                <img src={require("../../assets/img/comment/comment-1.jpg")} alt="" />
                                            </div>
                                            <div className="media-body">
                                                <a href=".#"><h4>Merry John</h4></a>
                                                <p>Duis aute irure dolor in reprehenderit in vol uptate velit esse cillum dolore eu fugiat nulla pari atur. Excepteur sint occaecat cupidatat non proid pent.</p>
                                                <div className="date_rep">
                                                    <a href=".#">Nov 27 2017</a>
                                                    <a href=".#">Reply</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="s_comment_area">
                                    <div className="b_single_title">
                                        <h3>Leave a Comment</h3>
                                    </div>
                                    <div className="s_comment_inner">
                                        <form className="row contact_us_form" action="contact_process.php" method="post" id="contactForm" novalidate="novalidate">
                                            <div className="form-group col-md-6">
                                                <input type="text" className="form-control" id="name" name="name" placeholder="Your name" />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <input type="email" className="form-control" id="email" name="email" placeholder="Email address" />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <textarea className="form-control" name="message" id="message" rows="1" placeholder="Wrtie message"></textarea>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <button type="submit" value="submit" className="btn submit_btn form-control">submit now <strong className="icon"><i className="fa fa-paper-plane" aria-hidden="true"></i></strong></button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="right_sidebar">
                                <SearchWD />
                                <CategoriesWD />
                                <RecentPostWD />
                                <TextWD Text="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium dolore que laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi arch itecto beatae vitae dict eaque ipsa quae" />
                                <TagWD />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default BlogDetails;