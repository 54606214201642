import React, {Component} from 'react';
import WDTitle from './WDTitle';
import CTList from './CTList';

class CategoriesWD extends Component{
    render(){
        return(
            <aside className="r_widget ct_widget">
                <WDTitle Title="Categories" />
                <ul className="list">
                    <CTList Url="#" Text="Reach the perfect audience" />
                    <CTList Url="#" Text="Save time and resources" />
                    <CTList Url="#" Text="Get a better return on your investment" />
                    <CTList Url="#" Text="Merchant Services" />
                    <CTList Url="#" Text="Telecommunications Services" />
                    <CTList Url="#" Text="IT & Web Development" />
                    <CTList Url="#" Text="Marketing Services & Supplies" />
                </ul>
            </aside>
        )
    }
}
export default CategoriesWD;