import React, {Component} from 'react';


class FillterMenu extends Component{
    render(){
        var {Class, ID, Name} = this.props;
        return(
            <li className={Class} data-filter={ID}><a href=".#">{Name}</a></li>
        )
    }
}
export default FillterMenu;