import React, {Component} from 'react';
import Chart from "chart.js";

class GrowthReport extends Component{
    componentDidMount() {
        var ctx = document.getElementById("myChart");
        new Chart(ctx, {
            type: 'line',
            data: {
                labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
                datasets: [{
                    label: 'Remove',
                    data: [12, 19, 3, 5, 2, 3],
                    backgroundColor: [
                        'rgba(0, 123, 255, 0.2)',
                    ],
                    borderColor: [
                        'rgba(0, 123, 255, 1)',
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero:true
                        }
                    }]
                }
            }
        });
    }
    render(){
        return(
            <div className="growth_strategy pad_top">
                <div className="single_title">
                    <h2>{this.props.Title}</h2>
                    <p>{this.props.Details}</p>
                </div>
                <canvas id="myChart" style={{width: '100%', minHeight: '400px'}}></canvas>
            </div>        
        )
    }
}
export default GrowthReport;