import React, {Component} from 'react';


class GalleryItem extends Component{
    render(){
        var {ID, Image, Url, BtnText, Details} = this.props;
        return(
            <div className={"col-lg-3 " + ID}>
                <div className="g_classic_item">
                    <div className="g_img">
                        <img className="img-fluid" src={require("../../assets/img/gallery/" + Image)} alt="" />
                        <a href={Url}>{BtnText}</a>
                    </div>
                    <div className="g_content">
                        <a href={Url}><p>{Details}</p></a>
                    </div>
                </div>
            </div>
        )
    }
}
export default GalleryItem;