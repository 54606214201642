import React, {Component} from 'react';

class TeamListItem extends Component{
    render(){
        var {Image, Name, Designation, Url} = this.props;
        return(
            <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="team_two_item">
                    <img className="img-fluid" src={require("../../assets/img/team/" + Image)} alt="" />
                    <div className="team_text">
                        <a href={Url}><h4>{Name}</h4></a>
                        <p>{Designation}</p>
                    </div>
                </div>
            </div>
        )
    }
}
export default TeamListItem;