import React, {Component} from 'react';

class TagsList extends Component{
    render(){
        var {Url, Text} = this.props;
        return(
            <li><a href={Url}>{Text}</a></li>
        )
    }
}
export default TagsList;