import React, {Component} from 'react';

class Error extends Component{
    render(){
        return(
            <section className="error_area p_100">
                <div className="container">
                    <div className="error_content">
                        <h3>404</h3>
                        <h4>This page is not be found</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi non ligula consectetur, consequat arcu quis, aliquet erat. Nullam a massa venenatis, commodo velit. Aenean nec facilisis dui, sed molestie metus. </p>
                        <center><a className="main_btn" href="/">Back to Home Page <strong class="icon"><i class="fa fa-arrow-left" aria-hidden="true"></i></strong></a></center>
                    </div>
                </div>
            </section>
        )
    }
}
export default Error;