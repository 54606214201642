import React, {Component} from 'react';

class AboutCompany extends Component{
    render(){
        var {Image, Title, Subtitle, Details1, Details2} = this.props;
        return(
            <section className="about_hotel_area p_100">
                <div className="container">
                    <div className="row about_hotel_inner align-items-center d-flex">
                        <div className="col-lg-8">
                            <div className="ab_hotel_text">
                                <div className="main_title">
                                    <h2>{Title}</h2>
                                </div>
                                <h5>{Subtitle}</h5>
                                <p>{Details1}</p>
                                <p>{Details2}</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="ab_hotel_img">
                                <img className="img-fluid" src={require("../../assets/img/" + Image)} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default AboutCompany;