import React from 'react';
import HeaderTopBar from '../HeaderTopBar';
import Footer from '../Elements/Footer'; 
import Breadcrumb from '../Elements/Breadcrumb';
import AboutCompany from '../Elements/AboutCompany';
import WorkProcess from '../Elements/WorkProcess';
import ReportChart from '../Elements/ReportChart';
import ClientSlider from '../Elements/ClientSlider';



export const AboutUs = () => (
    <div className="body_wrapper">
        <HeaderTopBar Logo="logo.png" />
        <Breadcrumb PageName="About Us" PageLink="AboutUs" />
        <AboutCompany 
            Title="About Our Company" 
            Subtitle="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
            Details1="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable." 
            Details2="At the end of the day, going forward, a new normal that has evolved from generation X is on the runway heading towards a streamlined cloud solution. User generated content in real-time will have multiple touchpoints for offshoring." 
            Image="about-company.jpg" 
        />
        <WorkProcess />
        <ReportChart 
            Title="Annual Report from starting to now" 
            Details1="Both rest of know draw fond post as. It agreement defective to excellent. Feebly do engage of narrow. Extensive repulsive belonging depending if promotion Me smallness is existence attending he enjoyment favourite affection. Delivered is to ye belonging enjoyment preferred." 
            Details2="Drawings me opinions returned absolute in. Otherwise therefore sex did are unfeeling something. Certain be ye amiable by exposed so. To celebrated estimating excellence do." 
            BtnText="Read More" 
            BtnUrl="#" 
        />
        <ClientSlider />
        <Footer CPLeft="© Copyright MarketWatch" CPRight="All right reserved." />
    </div>
)