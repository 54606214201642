import React, {Component} from 'react';

class SingleBlogItem extends Component{
    render(){
        var {Image, Title, Details1, Details2, Details3, Author, Comment, Url} = this.props;
        return(
            <div className="main_blog_item">
                <div className="main_blog_img">
                    <img className="img-fluid" src={require("../../assets/img/blog/main-blog/" + Image)} alt="" />
                </div>
                <div className="blog_text">
                    <ul className="list">
                        <li><a href={Url}><i className="fa fa-user"></i>{Author}</a></li>
                        <li><a href={Url}><i className="fa fa-comment"></i>{Comment}</a></li>
                    </ul>
                    <a href={Url}><h4>{Title}</h4></a>
                    <p>{Details1}</p>
                    <p>{Details2}</p>
                    <p>{Details3}</p>
                </div>
            </div>
        )
    }
}
export default SingleBlogItem;