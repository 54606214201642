import React, {Component} from 'react';
import Address from '../components/MenuTopBar/Address';
import EmailAddress from '../components/MenuTopBar/EmailAddress';


class HeaderTopBar extends Component{
    render(){
        return(
            <header className="header_area menu_five"> 
				<div className="top_logo">
					<div className="container">
						<div className="row m0 justify-content-between">
							<div className="left_logo">
								<a href="../">
									<img src={require('../assets/img/' + this.props.Logo)} alt=""/>
								</a>
							</div>
							<div className="right_logo">
								<Address Icon="map-marker" Address="B - 562, Mallin Street New York, West Col, NY 100 254" />
								<EmailAddress Icon="phone" Number="1800 456 7890" Email="rockybd1995@gmail.com" />
							</div>
						</div>
					</div>
				</div>
				<div className="main_menu">
					<div className="container">
						<nav className="navbar navbar-expand-lg navbar-light bg-light">
							<a className="navbar-brand" href="index.html"><img src="img/logo-2.png" alt="" /></a>
							<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
								<span></span>
							</button>
							<div className="collapse navbar-collapse" id="navbarSupportedContent">
								<ul className="nav navbar-nav menu_nav mr-auto">
								<li><a href="../">Home</a></li>
									<li><a href="/Pages/AboutUs">About Us</a></li>
									<li className="dropdown submenu">
										<a className="dropdown-toggle" data-toggle="dropdown" href=".#" role="button" aria-haspopup="true" aria-expanded="false">Services</a>
										<ul className="dropdown-menu">
											<li><a href="/Pages/Service">Service</a></li>
											<li><a href="/Pages/BusinessLoan">Business Loan</a></li>
											<li><a href="/Pages/Planning">Financial Planning</a></li>
											<li><a href="/Pages/BusinessGrowth">Business Growth</a></li>
											<li><a href="/Pages/Investment">Investment In Bonds</a></li>
											<li><a href="/Pages/MutualFunds">Mutual Funds</a></li>
										</ul>
									</li>
									<li className="dropdown submenu">
										<a className="dropdown-toggle" data-toggle="dropdown" href=".#" role="button" aria-haspopup="true" aria-expanded="false">Blog</a>
										<ul className="dropdown-menu">
											<li><a href="/Pages/Blog">Blog with sidebar</a></li>
											<li><a href="/Pages/BlogTwoColumn">Blog 2 column</a></li>
											<li><a href="/Pages/SingleBlog">Blog details</a></li>
										</ul>
									</li>
									<li className="dropdown submenu">
										<a className="dropdown-toggle" data-toggle="dropdown" href=".#" role="button" aria-haspopup="true" aria-expanded="false">Pages</a>
										<ul className="dropdown-menu">
											<li className="dropdown submenu">
												<a className="dropdown-toggle" data-toggle="dropdown" href=".#" role="button" aria-haspopup="true" aria-expanded="false">Gallery</a>
												<ul className="dropdown-menu">
													<li><a href="/Pages/Gallery">-  Gallery Classic</a></li>
													<li><a href="/Pages/GalleryFullwidth">-  Gallery Two Column</a></li>
												</ul>
											</li>
											<li className="dropdown submenu">
												<a className="dropdown-toggle" data-toggle="dropdown" href=".#" role="button" aria-haspopup="true" aria-expanded="false">Our Team</a>
												<ul className="dropdown-menu">
													<li><a href="/Pages/Team">Team</a></li>
													<li><a href="/Pages/SingleTeam">Team Single</a></li>
												</ul>
											</li>
										</ul>
									</li>
									<li><a href="/Pages/Contact">Contact Us</a></li>
								</ul>
								<ul className="nav navbar-nav navbar-right">
									<li className="search_icon">
										<div className="input-group">
											<input type="text" className="form-control" placeholder="Enter Keywords" />
											<div className="input-group-append">
												<button className="btn" type="button" id="button-addon2"><i className="icon icon-Search"></i></button>
											</div>
										</div>
									</li>
								</ul>
							</div>
						</nav>
					</div>
				</div>
			</header>
        );
    }
}
export default HeaderTopBar;