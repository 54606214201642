import React, {Component} from 'react';
import WDTitle from './WDTitle';
import TagsList from './TagsList';

class TagWD extends Component{
    render(){
        return(
            <aside className="r_widget tag_widget">
                <WDTitle Title="Tags" />
                <ul className="list">
                    <TagsList Url="#" Text="Reach" />
                    <TagsList Url="#" Text="Save" />
                    <TagsList Url="#" Text="Investment" />
                    <TagsList Url="#" Text="Merchant" />
                    <TagsList Url="#" Text="Services" />
                    <TagsList Url="#" Text="Development" />
                    <TagsList Url="#" Text="Marketing" />
                    <TagsList Url="#" Text="Supplies" />
                </ul>
            </aside>
        )
    }
}
export default TagWD;