import React, {Component} from 'react';

class LoanFeature extends Component{
    render(){
        var {Icon, Title, Details} = this.props;
        return(
            <div className="col-lg-6">
                <div className="loan_f_item">
                    <div className="media">
                        <div className="d-flex">
                            <i className={Icon} aria-hidden="true"></i>
                        </div>
                        <div className="media-body">
                            <h4>{Title}</h4>
                            <p>{Details}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default LoanFeature;