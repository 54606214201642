import React, {Component} from 'react';
import BlogListItem from './BlogListItem';
import SearchWD from './widgets/SearchWD';
import CategoriesWD from './widgets/CategoriesWD';
import RecentPostWD from './widgets/RecentPostWD';
import TagWD from './widgets/TagWD';
import TextWD from './widgets/TextWD';

class BlogList extends Component{
    render(){
        return(
            <section className="main_blog_area p_100">
                <div className="container">
                    <div className="row main_blog_inner">
                        <div className="col-lg-9">
                            <div className="blog_item_inner">
                                <BlogListItem 
                                    Image="blog-1.jpg" 
                                    Title="Accusantium Dolore Mque Laud Antium Dolore Mque" 
                                    Details="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
                                    BtnText="Read More"
                                    Url="#"
                                />
                                <BlogListItem 
                                    Image="blog-2.jpg" 
                                    Title="It is a long established fact that a reader." 
                                    Details="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
                                    BtnText="Read More"
                                    Url="#"
                                />
                                <BlogListItem 
                                    Image="blog-3.jpg" 
                                    Title="The point of using Lorem Ipsum is that it has a more" 
                                    Details="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
                                    BtnText="Read More"
                                    Url="#"
                                />
                                <BlogListItem 
                                    Image="blog-4.jpg" 
                                    Title="Making it look like point of using readable English." 
                                    Details="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
                                    BtnText="Read More"
                                    Url="#"
                                />
                                <BlogListItem 
                                    Image="blog-5.jpg" 
                                    Title="Accusantium Dolore Mque Laud Antium Dolore Mque" 
                                    Details="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
                                    BtnText="Read More"
                                    Url="#"
                                />
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a className="page-link" href=".#" aria-label="Previous">
                                        <span aria-hidden="true">&laquo;</span>
                                        <span className="sr-only">Previous</span>
                                        </a>
                                    </li>
                                    <li className="page-item"><a className="page-link" href=".#">1</a></li>
                                    <li className="page-item"><a className="page-link" href=".#">2</a></li>
                                    <li className="page-item"><a className="page-link" href=".#">3</a></li>
                                    <li className="page-item">
                                        <a className="page-link" href=".#" aria-label="Next">
                                        <span aria-hidden="true">&raquo;</span>
                                        <span className="sr-only">Next</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-lg-3">
                            <div className="right_sidebar">
                                <SearchWD />
                                <CategoriesWD />
                                <RecentPostWD />
                                <TextWD Text="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium dolore que laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi arch itecto beatae vitae dict eaque ipsa quae" />
                                <TagWD />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default BlogList;