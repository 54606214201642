import React, {Component} from 'react';
import Title2 from './Title2';
import WorkItem from './WorkItem';

class WorkProcess extends Component{
    render(){
        return(
            <section className="work_process_area p_100">
                <div className="container">
                    <Title2 Title="WORK PROCESS" Subtitle="Able an hope of body. Any nay shyness article matters own removal nothing his forming. Gay own additions education satisfied the perpetual. If he cause manor happy. Without farther she exposed saw man led." />
                    <div className="row work_process_inner">
                        <WorkItem Icon="fa-users" Title="Meetup" Description="Evening do forming observe spirits is in. Country hearted be of justice sending. On so they as with room cold" />
                        <WorkItem Icon="fa-file" Title="Collect Info" Description="Evening do forming observe spirits is in. Country hearted be of justice sending. On so they as with room cold" />
                        <WorkItem Icon="fa-pie-chart" Title="Implement Plan" Description="Evening do forming observe spirits is in. Country hearted be of justice sending. On so they as with room cold" />
                        <WorkItem Icon="fa-user-secret" Title="Start Work" Description="Evening do forming observe spirits is in. Country hearted be of justice sending. On so they as with room cold" />
                        <WorkItem Icon="fa-check" Title="Business Development" Description="Evening do forming observe spirits is in. Country hearted be of justice sending. On so they as with room cold" />
                        <WorkItem Icon="fa-cogs" Title="Financial Planing" Description="Evening do forming observe spirits is in. Country hearted be of justice sending. On so they as with room cold" />
                    </div>
                </div>
            </section>
        )
    }
}
export default WorkProcess;