import React from 'react';


import HeaderTopBar from '../HeaderTopBar';
import Breadcrumb from '../Elements/Breadcrumb';
import Footer from '../Elements/Footer'; 
import SingleTeamInfo from '../Elements/SingleTeamInfo';
import TeamList from '../Elements/TeamList';

export const Team = () => (
    <div className="body_wrapper">
        <HeaderTopBar Logo="logo.png" />
        <Breadcrumb PageName="Team" PageLink="Team" />
        <SingleTeamInfo 
            Image="personal-2.jpg"
            Message="HELLO EVERYBODY, I AM"
            Name="DONALD MCKINNEY"
            Designation="FORNTEND DEVELOPER"
            Details="You will begin to realise why this exercise is called the Dickens Pattern (with reference to the ghost showing Scrooge some different futures)"
            Date="31st December, 1992"
            Number="+8801676790690"
            Email="rockybd1995@gmail.com"
            Address="Santa monica bullevard"
        />
        <TeamList />
        <Footer CPLeft="© Copyright MarketWatch" CPRight="All right reserved." /> 
    </div>
)