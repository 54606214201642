import React, {Component} from 'react';


class BlogListItem extends Component{
    render(){
        var {Image, Title, Details, BtnText, Url} = this.props;
        return(
            <article className="main_blog_item">
                <div className="main_blog_img">
                    <img className="img-fluid" src={require("../../assets/img/blog/main-blog/" + Image)} alt="" />
                </div>
                <div className="blog_text">
                    <ul className="list">
                        <li><a href=".#"><i className="fa fa-user"></i>admin</a></li>
                        <li><a href=".#"><i className="fa fa-comment"></i>comments:04</a></li>
                    </ul>
                    <a href={Url}><h4>{Title}</h4></a>
                    <p>{Details}</p>
                    <a className="main_btn" href={Url}>{BtnText} <strong className="icon"><i className="fa fa-repeat" aria-hidden="true"></i></strong></a>
                </div>
            </article>
        )
    }
}
export default BlogListItem;