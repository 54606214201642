import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';

/*------ Pages-----*/
import {Home} from './components/Pages/Home'; 
import {AboutUs} from './components/Pages/AboutUs';  
import {Service} from './components/Pages/Service';
import {BusinessLoan} from './components/Pages/BusinessLoan';
import { Planning } from './components/Pages/Planning';
import { BusinessGrowth } from './components/Pages/BusinessGrowth';
import { Investment } from './components/Pages/Investment';
import { MutualFunds } from './components/Pages/MutualFunds';
import { Blog } from './components/Pages/Blog';
import { BlogTwoColumn } from './components/Pages/BlogTwoColumn';
import { SingleBlog } from './components/Pages/SingleBlog';
import { Gallery } from './components/Pages/Gallery';
import { GalleryFullwidth } from './components/Pages/GalleryFullwidth';
import { Team } from './components/Pages/Team';
import { SingleTeam } from './components/Pages/SingleTeam';
import { Contact } from './components/Pages/Contact';
import { NotFound } from './components/Pages/NotFound';


class App extends Component{
  render(){
    return(
        <Router>
           <Switch>
             <Route exact={true} path={'/'} component={Home}/>
             <Route path="/Pages/AboutUs" component={AboutUs} />
             <Route path="/pages/Service" component={Service} />
             <Route path="/pages/BusinessLoan" component={BusinessLoan} />
             <Route path="/pages/Planning" component={Planning} />
             <Route path="/pages/BusinessGrowth" component={BusinessGrowth} />
             <Route path="/pages/Investment" component={Investment} />
             <Route path="/pages/MutualFunds" component={MutualFunds} />
             <Route path="/pages/Blog" component={Blog} />
             <Route path="/pages/BlogTwoColumn" component={BlogTwoColumn} />
             <Route path="/pages/SingleBlog" component={SingleBlog} />
             <Route path="/pages/Gallery" component={Gallery} />
             <Route path="/pages/GalleryFullwidth" component={GalleryFullwidth} />
             <Route path="/pages/Team" component={Team} />
             <Route path="/pages/SingleTeam" component={SingleTeam} />
             <Route path="/pages/Contact" component={Contact} />
             <Route component={NotFound} />
           </Switch>
        </Router>
    );
  }
}
export default App;
