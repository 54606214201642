import React, {Component} from 'react';


class Breadcrumb extends Component{
    render(){
        var {PageName, PageLink} = this.props;
        return(
            <section className="breadcrumb_area">
                <div className="container">
                    <div className="breadcrumb_inner">
                        <h2>{PageName}</h2>
                        <div className="link">
                            <a href="../">Home</a>
                            <a href={"../Pages/" + PageLink}>{PageName}</a>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default Breadcrumb;