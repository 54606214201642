import React, {Component} from 'react';
import FTWDTitle from './FTWDTitle';
class FTLinkWD extends Component{
    render(){
        let FooterData = this.props.FooterData;
        return(
            <div className="col-lg-2 col-md-6">
                <aside className="f_widget link_widget">
                    <FTWDTitle TitleText="Quick Links" />
                    <ul className="list">
                        {
                            FooterData.PagesLink.map(item =>{
                                return(
                                    
                                    <li key={item.id}><a href={item.url}>{item.Text}</a></li>
                                )
                            })
                        }
                    </ul>
                </aside>
            </div>
        )
    }
}
export default FTLinkWD;