import React, {Component} from 'react';

class FServiceItem extends Component{
    render(){
        var {Image, Title, Details, BtnText, Url} = this.props;
        return(
            <div className="col-lg-4 col-md-6">
                <div className="service_item">
                    <div className="service_img">
                        <img className="img-fluid" src={require("../../assets/img/service/" + Image)} alt="" />
                    </div>
                    <div className="service_text">
                        <a href=".#"><h4>{Title}</h4></a>
                        <p>{Details}</p>
                        <a className="main_btn" href={Url}>{BtnText} <strong className="icon"><i className="fa fa-repeat" aria-hidden="true"></i></strong></a>
                    </div>
                </div>
            </div>
        )
    }
}
export default FServiceItem;