import React, {Component} from 'react'
import TeamListItem from './TeamListItem'


class TeamList extends Component{
    render(){
        return(
            <section className="team_two_area p_100">
                <div className="container">
                    <div className="row team_two_inner">
                        <TeamListItem 
                            Image="team-4.jpg"
                            Name="Rocky Ahmed"
                            Designation="Chairman"
                            Url="#"
                        />
                        <TeamListItem 
                            Image="team-5.jpg"
                            Name="Jhon Anderson"
                            Designation="Chairman"
                            Url="#"
                        />
                        <TeamListItem 
                            Image="team-6.jpg"
                            Name="David Clark"
                            Designation="Chairman"
                            Url="#"
                        />
                        <TeamListItem 
                            Image="team-7.jpg"
                            Name="Robert Son"
                            Designation="Chairman"
                            Url="#"
                        />
                        <TeamListItem 
                            Image="team-1.jpg"
                            Name="James Bond"
                            Designation="Chairman"
                            Url="#"
                        />
                        <TeamListItem 
                            Image="team-3.jpg"
                            Name="Ander Son"
                            Designation="Chairman"
                            Url="#"
                        />
                        <TeamListItem 
                            Image="team-2.jpg"
                            Name="Dynamo"
                            Designation="Owner"
                            Url="#"
                        />
                    </div>
                </div>
            </section>
        )
    }
}
export default TeamList;