import React, {Component} from 'react';


class BlogTwoColumnItem extends Component{
    render(){
        var {Image, Title, Details, BtnText, Url, Comment, Author} = this.props;
        return(
            <div class="col-lg-6">
                <article class="main_blog_item">
                    <div class="main_blog_img">
                        <img class="img-fluid" src={require("../../assets/img/blog/main-blog/" + Image)} alt="" />
                    </div>
                    <div class="blog_text">
                        <ul class="list">
                            <li><a href={Url}><i class="fa fa-user"></i>{Author}</a></li>
                            <li><a href={Url}><i class="fa fa-comment"></i>{Comment}</a></li>
                        </ul>
                        <a href={Url}><h4>{Title}</h4></a>
                        <p>{Details}</p>
                        <a class="main_btn" href={Url}>{BtnText} <strong class="icon"><i class="fa fa-repeat" aria-hidden="true"></i></strong></a>
                    </div>
                </article>
            </div>
        )
    }
}
export default BlogTwoColumnItem;