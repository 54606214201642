import React, {Component} from 'react';


class WDTitle extends Component{
    render(){
        return(
            <div className="r_w_title">
                <h3>{this.props.Title}</h3>
            </div>
        )
    }
}
export default WDTitle;