import React from 'react';

import HeaderTopBar from '../HeaderTopBar';
import Breadcrumb from '../Elements/Breadcrumb';
import Footer from '../Elements/Footer'; 
import PlanningDetails from '../Elements/PlanningDetails';



export const Planning = () => (
    <div className="body_wrapper">
        <HeaderTopBar Logo="logo.png" />
        <Breadcrumb PageName="Financial Planning" PageLink="Planning" />
        <PlanningDetails />
        <Footer CPLeft="© Copyright MarketWatch" CPRight="All right reserved." /> 
    </div>
)