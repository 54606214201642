import React from 'react';
import HeaderTopBar from '../HeaderTopBar';
import HomeSmallService from '../Elements/HomeSmallService';
import CreateBusiness from '../Elements/CreateBusiness';
import OurTeam from '../Elements/OurTeam';
import TestimonialsSlider from '../Elements/TestimonialsSlider';
import HomeBlog from '../Elements/HomeBlog';
import HomeContact from '../Elements/HomeContact';
import Footer from '../Elements/Footer'; 
import HomeSlider from '../Elements/HomeSlider';



export const Home = () => (
    <div className="body_wrapper">
        <HeaderTopBar Logo="logo.png" />
        <HomeSlider />
        <HomeSmallService />
        <CreateBusiness Image="business-monitor.png" Title="WHO CREAT BUSINESS" Description="Neque porro quisquam est, qui dolorem ipsum quia dolor sit met consectetur, adipisci velit Neque porro quisquam est, qui dolorem ipsum quia dolor sit met consectetur, adipisci velit porro quisquam est, qui dolorem ipsum quia dolor sit met consectetur, adipisci velit Neque porro quisquam est." />
        <OurTeam Title="Our Team" Description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer." />
        <TestimonialsSlider />
        <HomeBlog />
        <HomeContact Address="B - 562, Mallin Street New York, West Col, NY 100 254" Number="1800 456 7890" Email="rockybd1995@gmail.com" CheckIn="3.00pm" CheckOut="11.00am" Image="personal-3.jpg" />
        <Footer CPLeft="© Copyright MarketWatch" CPRight="All right reserved." />
    </div>
)