import React, {Component} from 'react';
import LoanText from './LoanText';
import LoanFeature from './LoanFeature';

class LoanDetails extends Component{
    render(){
        return(
            <section className="service_details_inner p_100">
                <div className="container">
                    <div className="row service_details_inner flex-row-reverse">
                        <div className="col-lg-9">
                            <div className="mutual_funds_text">
                                <LoanText 
                                    Title="BUSINESS LOAN" 
                                    Details1="Ruis nostrum exercitationem ullam corporis suscipit laboriosam, nisi utau aliqu id etx ea commodi consequatur? Quis autem vel eum iure reprehen derit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo. nim ad minima veniam, quis nostrum exercitationem" 
                                    Details2="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable." 
                                    Image="m-service-2.jpg" 
                                />
                            </div>
                            <div className="row loan_feature pad_top">
                                <LoanFeature Icon="fa fa-line-chart" Title="Get Maximum Profit" Details="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour." />
                                <LoanFeature Icon="fa fa-pie-chart" Title="Tranparancey Policy" Details="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour." />
                                <LoanFeature Icon="fa fa-trophy" Title="Pay For Success" Details="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour." />
                                <LoanFeature Icon="fa fa-credit-card" Title="Personal Credit Score" Details="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour." />
                                <LoanFeature Icon="fa fa-clock-o" Title="Time in Business" Details="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour." />
                                <LoanFeature Icon="fa fa-leanpub" Title="Length of the loan term" Details="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour." />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="left_sidebar_area">
                                <aside className="left_widget service_list_widget">
                                    <ul className="list">
                                        <li className="active"><a href="/Pages/BusinessLoan">Business Loan <i className="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                        <li><a href="/Pages/Planning">Financial Planning <i className="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                        <li><a href="/Pages/BusinessGrowth">Business Growth <i className="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                        <li><a href="/Pages/Investment">Invesment In Bonds <i className="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                        <li><a href="/Pages/MutualFunds">Mutual Funds <i className="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                    </ul>
                                </aside>
                                <aside className="left_widget contact_s_widget">
                                    <h4>Contact Us</h4>
                                    <p>We here to help in anything you need. Call us on below number.</p>
                                    <a href="tel:+8801676790690"><i className="lnr lnr-phone-handset"></i>+88016 76790 690</a>
                                    <a href="mailto:rockybd1995@gmail.com"><i className="lnr lnr-envelope"></i>rockybd1995@gmail.com</a>
                                </aside>
                                <aside className="left_widget download_widget">
                                    <a href=".#"><i className="lnr lnr-download"></i> Download Your PDF</a>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default LoanDetails;