import React, {Component} from 'react';
import Chart from "chart.js";

class ReportChart extends Component{
    componentDidMount() {
        var ctx = document.getElementById("myChart");
        new Chart(ctx, {
            type: 'line',
            data: {
                labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
                datasets: [{
                    label: 'Remove',
                    data: [12, 19, 3, 5, 2, 3],
                    backgroundColor: [
                        'rgba(0, 123, 255, 0.2)',
                    ],
                    borderColor: [
                        'rgba(0, 123, 255, 1)',
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero:true
                        }
                    }]
                }
            }
        });
    }
    render(){
        var {Title, Details1, Details2, BtnText, BtnUrl} = this.props;
        return(
            <section className="annual_report_area p_100">
                <div className="container">
                    <div className="row annual_report_inner align-items-center">
                        <div className="col-lg-6">
                            <div className="report_text">
                                <h4>{Title}</h4>
                                <p>{Details1}</p>
                                <p>{Details2}</p>
                                <a className="main_btn" href={BtnUrl}>{BtnText} <strong className="icon"><i className="fa fa-repeat" aria-hidden="true"></i></strong></a>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <canvas id="myChart" style={{width: '100%', minHeight: '400px'}}></canvas>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default ReportChart;