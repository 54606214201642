import React, {Component} from 'react';
import Title2 from './Title2';
import FServiceItem from './FServiceItem';

class FasterService extends Component{
    render(){
        return(
            <section className="service_area p_100">
                <div className="container">
                    <Title2 Title="WE PROVIDE FASTER SERVICE" Subtitle="Able an hope of body. Any nay shyness article matters own removal nothing his forming. Gay own additions education satisfied the perpetual. If he cause manor happy. Without farther she exposed saw man led." />
                    <div className="row service_inner">
                        <FServiceItem 
                            Image="service-1.jpg" 
                            Title="Business Consulting" 
                            Details="Voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt reprehenderit."
                            BtnText="Learn More"
                            Url="#"
                        />    
                        <FServiceItem 
                            Image="service-2.jpg" 
                            Title="Mutul Funds" 
                            Details="Voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt reprehenderit."
                            BtnText="Learn More"
                            Url="#"
                        />  
                        <FServiceItem 
                            Image="service-3.jpg" 
                            Title="Success Report" 
                            Details="Voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt reprehenderit."
                            BtnText="Learn More"
                            Url="#"
                        />  
                    </div>
                </div>
            </section>
        )
    }
}
export default FasterService;