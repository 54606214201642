import React from 'react';

import HeaderTopBar from '../HeaderTopBar';
import Breadcrumb from '../Elements/Breadcrumb';
import Footer from '../Elements/Footer'; 
import BlogTwoColumnList from '../Elements/BlogTwoColumnList';

export const BlogTwoColumn = () => (
    <div className="body_wrapper">
        <HeaderTopBar Logo="logo.png" />
        <Breadcrumb PageName="BLOG TWO COLUMN" PageLink="BlogTwoColumn" />
        <BlogTwoColumnList />
        <Footer CPLeft="© Copyright MarketWatch" CPRight="All right reserved." /> 
    </div>
)