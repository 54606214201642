import React, {Component} from "react";

class CTList extends Component{
    render(){
        var {Url, Text} = this.props;
        return(
           
            <li><a href={Url}>{Text}</a></li>
              
        )
    }
}
export default CTList;