import React from 'react';

import HeaderTopBar from '../HeaderTopBar';
import Breadcrumb from '../Elements/Breadcrumb';
import BlogDetails from '../Elements/BlogDetails';
import Footer from '../Elements/Footer'; 

export const SingleBlog = () => (
    <div className="body_wrapper">
        <HeaderTopBar Logo="logo.png" />
        <Breadcrumb PageName="Blog Details" PageLink="SingleBlog" />
        <BlogDetails />
        <Footer CPLeft="© Copyright MarketWatch" CPRight="All right reserved." /> 
    </div>
)