import React, {Component} from 'react';

class ContactForm extends Component{
    render(){
        return(
            <section className="contact_area p_100">
                <div className="container">
                    <div className="single_title">
                        <h2>Feel free to drop us a message</h2>
                        <p>Do you have anything in your brain to let us know? Kindly don't falter to connect to us through our contact form.</p>
                    </div>
                    <div className="row contact_inner">
                        <div className="col-lg-9">
                            <form className="row contact_us_form" action="contact_process.php" method="post" id="contactForm" novalidate="novalidate">
                                <div className="form-group col-lg-6">
                                    <input type="text" className="form-control" id="name" name="name" placeholder="Name" />
                                </div>
                                <div className="form-group col-lg-6">
                                    <input type="email" className="form-control" id="email" name="email" placeholder="Email address" />
                                </div>
                                <div className="form-group col-md-12">
                                    <input type="text" className="form-control" id="subject" name="subject" placeholder="Subject" />
                                </div>
                                <div className="form-group col-md-12">
                                    <input type="text" className="form-control" id="number" name="number" placeholder="Phone Number" />
                                </div>
                                <div className="form-group col-md-12">
                                    <textarea className="form-control" name="message" id="message" rows="1" placeholder="Wrtie message"></textarea>
                                </div>
                                <div className="form-group col-md-12">
                                    <button type="submit" className="btn submit_btn form-control">Submit Now <strong className="icon"><i className="fa fa-paper-plane" aria-hidden="true"></i></strong></button>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-3">
                            <div className="contact_details">
                                <div className="media">
                                    <div className="d-flex">
                                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                                    </div>
                                    <div className="media-body">
                                        <p>B - 562, Mallin Street New York, West Col, NY 100 254</p>
                                    </div>
                                </div>
                                <div className="media">
                                    <div className="d-flex">
                                        <i className="fa fa-envelope" aria-hidden="true"></i>
                                    </div>
                                    <div className="media-body">
                                        <a href="tel:18004567890"><span>Call Us: </span>1800 456 7890</a>
                                        <a href="mailto:rockybd1995@gmail.com"><span>Email : </span> rockybd1995@gmail.com</a>
                                    </div>
                                </div>
                                <div className="media">
                                    <div className="d-flex">
                                        <i className="fa fa-clock-o" aria-hidden="true"></i>
                                    </div>
                                    <div className="media-body">
                                        <h5>Check In : <span>3.00 pm</span></h5>
                                        <h5>Check Out : <span>11.00 am</span></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default ContactForm;