import React, {Component} from 'react';
import TeamSocial from './TeamSocial';

class SingleTeamInfo extends Component{
    render(){
        var {Image, Message, Name, Designation, Details, Date, Number, Email, Address,} = this.props;
        return(
            <section className="profile_area">
                <div className="container">
                    <div className="profile_inner p_100">
                        <div className="row align-items-center">
                            <div className="col-lg-5">
                                <img className="img-fluid" src={require("../../assets/img/" + Image)} alt="" />
                            </div>
                            <div className="col-lg-7">
                                <div className="personal_text">
                                    <h6>{Message}</h6>
                                    <h3>{Name}</h3>
                                    <h4>{Designation}</h4>
                                    <p>{Details}</p>
                                    <ul className="list basic_info">
                                        <li><a href=".#"><i className="lnr lnr-calendar-full"></i> {Date}</a></li>
                                        <li><a href=".#"><i className="lnr lnr-phone-handset"></i> {Number}</a></li>
                                        <li><a href=".#"><i className="lnr lnr-envelope"></i> {Email}</a></li>
                                        <li><a href=".#"><i className="lnr lnr-home"></i> {Address}</a></li>
                                    </ul>
                                    <ul className="list personal_social">
                                        <TeamSocial Icon="fa fa-facebook" Url="#" />
                                        <TeamSocial Icon="fa fa-twitter" Url="#" />
                                        <TeamSocial Icon="fa fa-linkedin" Url="#" />
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default SingleTeamInfo;